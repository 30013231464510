import React from "react"

import Layout from "../components/layout/Layout"
import LabelText from "../components/LabelText"
import Button from "../components/Button"
import partnerImage from "../images/chatchamp-partner.png"
import emarsyLogo from "../images/partner-logos/emarsys-logo.svg"
import googleAnalyticsLogo from "../images/partner-logos/google-analytics-logo.png"
import productsupLogo from "../images/partner-logos/productsup-logo.svg"
import salesforceLogo from "../images/partner-logos/salesforce-logo.svg"
import trboLogo from "../images/partner-logos/trbo-logo.svg"
import zenloopLogo from "../images/partner-logos/zenloop-logo-blue-web-rgb.png"
import zendeskLogo from "../images/partner-logos/zendesk-logo.svg"
import tawktoLogo from "../images/partner-logos/tawk-logo.png"

const PartnerPage = () => {
  return (
    <Layout>
      <section className="pt-16 md:pt-16">
        <div className="container mx-auto px-8">
          <div className="container mx-auto px-8 lg:flex">
            <div className="text-center pt-8 lg:text-left lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
                Chatchamps Technology Partner
              </h1>
              <p className="text-l lg:text-m lg:pr-12 mt-12 font-light">
                Unser umfassendes Technology Partner Netzwerk mit Anbietern aus
                jedem E-Commerce-relevanten Bereich, hilft dir Datensilos zu
                vermeiden und eine Customer Experience zu gewährleisten, die
                dein Kunde verdient.
              </p>
              <p className="text-l lg:text-m lg:pr-12 mt-12 font-light">
                Wir helfen dir gerne, aus unsere Technology Partner
                zielgerichtet zu wählen, sie in Ihr Projekt zu integrieren und
                bieten zahlreiche Schnittstellen zu den relevantesten Anbietern
                im Markt.
              </p>
              <div className="mt-8 md:mt-12">
                <Button
                  size="xl"
                  link="https://calendly.com/chatchamp/product-demo"
                >
                  Jetzt Partner werden
                </Button>
              </div>
            </div>
            <div className="front-page  lg:w-1/2 p-8">
              <img src={partnerImage} />
            </div>
          </div>
          <div>
            <div className="lg:my-20 my-8">
              <div className="m-8 container mx-auto text-center">
                <LabelText className="text-gray-700">
                  Customer Experience Partner 👑
                </LabelText>
              </div>
              <div className="m-8">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 list-none">
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-20"
                          src={emarsyLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Erstellen Sie dynamische und erfolgreiche Inhalte für
                          Ihre E-Mails und profitieren Sie von der Nummer Eins
                          der Engagement-Kanäle im Marketing. Begeistern Sie
                          Ihre Kunden mit optimaler Personalisierung und
                          verschicken Sie individuell zugeschnittene E-Mails an
                          beliebig viele Kontakte.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://emarsys.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> emarsys.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-12 mb-8"
                          src={zenloopLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Mit zenloop steht der Kunde im Mittelpunkt – Die
                          SaaS-Plattform für Kundenbindung und -rückgewinnung
                          auf Basis des Net Promoter Systems® (NPS). Die
                          Plattform verarbeitet Kundenfeedback mithilfe
                          künstlicher Intelligenz und identifiziert Ursachen für
                          Kundenabwanderung.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://zenloop.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> zenloop.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-16 mb-8"
                          src={trboLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          trbo ist führender Technologieanbieter für die
                          dynamische Onsite-Personalisierung, Optimierung und
                          Testing. Mit der KI-basierten Plattform von trbo
                          lassen sich Inhalte und Angebote von Webseiten
                          individuell und inspirierend gestalten – in Echtzeit
                          abgestimmt auf die Bedürfnislage von Kunden in ihrer
                          Customer Journey. Hierzu analysiert ein
                          selbstlernender Algorithmus das User-Verhalten auf
                          Basis von über 50 Besucher-Merkmalen. Diese Daten
                          erlauben anschließend eine zielgerichtete Auslieferung
                          verschiedenster Inhalte im Design des
                          Online-Auftritts, die das Einkaufserlebnis von
                          Website-Besuchern nachweislich optimieren. Namhafte
                          Händler und Hersteller wie Ströer, Telefónica, mydays,
                          Triumph, Vertbaudet und XXXLutz vertrauen auf die
                          SaaS-Lösung von trbo
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://trbo.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> trbo.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:my-20 my-8">
              <div className="m-8 container mx-auto text-center">
                <LabelText className="text-gray-700">
                  Customer Service Partner 💁‍
                </LabelText>
              </div>
              <div className="m-8">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 list-none">
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-20 mb-4"
                          src={salesforceLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Salesforce, die Customer Success Platform und der
                          weltweit führende Anbieter von Customer Relationship
                          Management (CRM)-Software ermöglicht Unternehmen sich
                          in einer komplett neuen Art und Weise mit ihren Kunden
                          zu vernetzen.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://salesforce.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> salesforce.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-12 mb-8"
                          src={zendeskLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Zendesk ist eine der weltweit führenden Plattformen
                          für Kundengespräche. Die Software vereint
                          Kundenbindung über E-Mail, Chat, Messaging und Telefon
                          auf einer einzigen Plattform für ein optimiertes
                          Kunden- und Agentenerlebnis.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://zendesk.de"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> zendesk.de
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-12 mb-8"
                          src={tawktoLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          tawk.to ist eine der führende Plattformen um
                          Kundengespräche via Live Chat zu führen. Durch die
                          Schnittstelle zu Chatchamp können sowohl Chatchamps
                          Guided Selling Lösung und Live Chat auf der Webseite
                          eingebunden werden.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://tawk.to"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> tawk.to
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:my-20 my-8">
              <div className="m-8 container mx-auto text-center">
                <LabelText className="text-gray-700">
                  Tracking & Analytics Partner 📊
                </LabelText>
              </div>
              <div className="m-8">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 list-none">
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-20 mb-4"
                          src={googleAnalyticsLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Mit Google Analytics können Sie nicht nur Umsatz und
                          Conversions messen, sondern erfahren auch, wie Nutzer
                          Ihre Website verwenden, wie sie dorthin kamen und wie
                          Sie aus diesen Nutzern regelmäßige Besucher machen
                          können.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://analytics.google.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> analytics.google.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:my-20 my-8">
              <div className="m-8 container mx-auto text-center">
                <LabelText className="text-gray-700">
                  Product Feed Management Partner ⚙️
                </LabelText>
              </div>
              <div className="m-8">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 list-none">
                  <li className="col-span-1 bg-white rounded-lg shadow">
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <img
                          className="shrink-0 h-20 mb-4"
                          src={productsupLogo}
                          alt=""
                        />
                        <p className="text-m font-light leading-relaxed">
                          Productsup ist die führende Cloud-Lösung für
                          Produktdaten-Management. Die Productsup-Plattform ist
                          eine cloudbasierte So ware, die entwickelt wurde, um
                          bei der effizienten Verarbeitung von Produktdaten zu
                          helfen.
                        </p>
                        <div className="mt-3">
                          <a
                            href="https://productsup.com"
                            className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                          >
                            -> productsup.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">
              In nur wenigen Schritten zum digitalen Produktberater!
            </h3>
            <p className="mt-8 text-xl font-light pr-16 pl-16">
              Teste Chatchamp und erstelle in wenigen Minuten deinen ersten
              digitalen Produktberater. Kundenzentrierte Beratung zu
              digitalisieren war noch nie so einfach.
            </p>
            <p className="mt-8">
              <Button size="xl" link="http://contact.chatchamp.com/testzugang">
                Jetzt testen
              </Button>
            </p>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export default PartnerPage
